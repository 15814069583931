import {pageNames} from '../constants';
import {AdminRight} from '../enums';

const config: Record<string, any>[] = [
  {
    label: 'APP管理',
    payload: [
      {
        label: pageNames.APP_CONFIG,
        path: '/app/config',
        match: '/app/config*',
      },
      {
        label: pageNames.APP_CLIENT_QA,
        path: '/app/client_question_answer',
        match: '/app/client_question_answer*',
      },
      {
        label: pageNames.APP_WORKER_QA,
        path: '/app/worker_question_answer',
        match: '/app/worker_question_answer*',
      },
      {
        label: pageNames.APP_ABOUT_US,
        path: '/app/about_us',
        match: '/app/about_us*',
      },
      {
        label: pageNames.APP_POST,
        path: '/app/post',
        match: '/app/post*',
      },
      {
        label: pageNames.APP_NOTIFICATION,
        path: '/app/notifications',
        match: '/app/notifications*',
      },
    ],
    rights: Object.values(AdminRight),
  },
  {
    label: '項目管理',
    payload: [
      {
        label: pageNames.CATEGORIES_SERVICES,
        path: '/categories/services',
        match: '/categories/services*',
      },
      {
        label: pageNames.CATEGORIES_ACCESSORIES,
        path: '/categories/accessories',
        match: '/categories/accessories*',
      },
      {
        label: pageNames.CATEGORIES_EQUIPMENTS,
        path: '/categories/equipments',
        match: '/categories/equipments*',
      },
      {
        label: pageNames.CATEGORIES_EQUIPMENT_TYPES,
        path: '/categories/equipment-types',
        match: '/categories/equipment-types*',
      },
      {
        label: pageNames.CATEGORIES_BRANDS,
        path: '/categories/brands',
        match: '/categories/brands*',
      },
    ],
    rights: Object.values(AdminRight),
  },
  {
    label: '報酬管理',
    payload: [
      {
        label: pageNames.PAYS,
        path: '/pays',
        match: '/pays*',
      },
    ],
    rights: Object.values(AdminRight),
  },
  {
    label: '維修訂單',
    payload: [
      // {
      //   label: '管理平台',
      //   path: '/home',
      //   match: '/home',
      // },
      {
        label: pageNames.ORDERS_DETAILS,
        path: '/orders/details',
        match: '/orders/details*',
      },
      // {
      //   label: pageNames.ORDERS_SCHEDULES,
      //   path: '/orders/schedules',
      //   match: '/orders/schedules*',
      // },
      {
        label: pageNames.ORDERS_PAYMENTS,
        path: '/orders/payments',
        match: '/orders/payments*',
      },
      {
        label: pageNames.ORDERS_REVIEWS,
        path: '/orders/reviews',
        match: '/orders/reviews*',
      },
      {
        label: pageNames.ORDERS_REPORT,
        path: '/orders/reports',
        match: '/orders/reports*',
      },
      {
        label: pageNames.ORDERS_INVOICES,
        path: '/orders/invoices',
        match: '/orders/invoices*',
      },
    ],
    rights: Object.values(AdminRight),
  },
  {
    label: '用戶管理',
    payload: [
      {
        label: pageNames.MEMBERS_CLIENTS,
        path: '/members/clients',
        match: '/members/clients*',
      },
      {
        label: pageNames.MEMBERS_WORKERS,
        path: '/members/workers',
        match: '/members/workers*',
      },
    ],
    rights: Object.values(AdminRight),
  },
  {
    label: '其他設定',
    payload: [
      {
        label: pageNames.HIGHLIGHT_PROMOTION,
        path: '/highlight/promotion',
        match: '/highlight/promotion*',
      },
      {
        label: pageNames.HIGHLIGHT_CONSTRUCTION,
        path: '/highlight/construction',
        match: '/highlight/construction*',
      },
      {
        label: pageNames.HIGHLIGHT_EQUIPMENTS,
        path: '/highlight/equipments',
        match: '/highlight/equipments*',
      },
      {
        label: pageNames.HIGHLIGHT_ACCESSORIES,
        path: '/highlight/accessories',
        match: '/highlight/accessories*',
      },
    ],
    rights: Object.values(AdminRight),
  },
  {
    label: '統計',
    payload: [
      {
        label: pageNames.ANALYTICS,
        path: '/analytics',
        match: '/analytics*',
      },
      // {
      //   label: pageNames.ANALYTICS_ORDER,
      //   path: '/analytics/orders',
      //   match: '/analytics/orders*',
      // },
      // {
      //   label: pageNames.ANALYTICS_WORKER,
      //   path: '/analytics/workers',
      //   match: '/analytics/workers*',
      // },
      // {
      //   label: pageNames.ANALYTICS_CLIENT,
      //   path: '/analytics/clients',
      //   match: '/analytics/clients*',
      // },
    ],
    rights: Object.values(AdminRight),
  },
  {
    label: '後台管理',
    payload: [
      {
        label: pageNames.CONSOLE_USER,
        path: '/console/users',
        match: '/console/users*',
      },
      // {
      //   label: pageNames.ANALYTICS_ORDER,
      //   path: '/analytics/orders',
      //   match: '/analytics/orders*',
      // },
      // {
      //   label: pageNames.ANALYTICS_WORKER,
      //   path: '/analytics/workers',
      //   match: '/analytics/workers*',
      // },
      // {
      //   label: pageNames.ANALYTICS_CLIENT,
      //   path: '/analytics/clients',
      //   match: '/analytics/clients*',
      // },
    ],
    rights: [AdminRight.SUPER_ADMIN],
  },
];

export default config;
