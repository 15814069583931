import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import logger from 'redux-logger';

import accessoryReducer from '../services/reducers/accessorySlice';
import adminReducer from '../services/reducers/adminSlice';
import appReducer from '../services/reducers/appSlice';
import authReducer from '../services/reducers/authSlice';
import brandReducer from '../services/reducers/brandSlice';
import equipmentReducer from '../services/reducers/equipmentSlice';
import equipmentTypeReducer from '../services/reducers/equipmentTypeSlice';
import highlightReducer from '../services/reducers/highlightSlice';
import orderReducer from '../services/reducers/orderSlice';
import paymentReducer from '../services/reducers/paymentSlice';
import payReducer from '../services/reducers/paySlice';
import postReducer from '../services/reducers/postSlice';
import reviewReducer from '../services/reducers/reviewSlice';
import serviceReducer from '../services/reducers/serviceSlice';
import {isLocalhost} from '../utils';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    admin: adminReducer,
    highlight: highlightReducer,
    service: serviceReducer,
    accessory: accessoryReducer,
    order: orderReducer,
    payment: paymentReducer,
    review: reviewReducer,
    pay: payReducer,
    post: postReducer,
    equipment: equipmentReducer,
    brand: brandReducer,
    equipmentType: equipmentTypeReducer,
  },
  middleware: getDefaultMiddleware =>
    isLocalhost
      ? getDefaultMiddleware({serializableCheck: false}).concat(logger)
      : getDefaultMiddleware({serializableCheck: false}),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
