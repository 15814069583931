import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {
  deleteDataFromList,
  prependListData,
  putListData,
} from '../../utils/apiHelper';
import {
  createEquipmentTypeThunk,
  deleteEquipmentTypeThunk,
  getEquipmentTypeByIdThunk,
  putEquipmentTypeByIdThunk,
  searchEquipmentTypesThunk,
} from '../thunks/equipmentTypeThunk';

// Define a type for the slice state

export interface EquipmentTypePayload {
  id: string;
  name: string;
}

interface EquipmentTypeState {
  equipmentTypes?: ListPayload<EquipmentTypePayload>;
  selectedEquipmentType?: EquipmentTypePayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: EquipmentTypeState = {
  equipmentTypes: undefined,
  selectedEquipmentType: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const equipmentTypeSlice = createSlice({
  name: 'equipmentType',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedEquipmentType = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createEquipmentTypeThunk.pending, (state, action) => {})
      .addCase(createEquipmentTypeThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.equipmentTypes, payload);
      })
      .addCase(createEquipmentTypeThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addCase(searchEquipmentTypesThunk.pending, (state, action) => {})
      .addCase(searchEquipmentTypesThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.equipmentTypes = payload;
      })
      .addCase(searchEquipmentTypesThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
        state.equipmentTypes = undefined;
      })

      .addCase(getEquipmentTypeByIdThunk.pending, (state, action) => {})
      .addCase(getEquipmentTypeByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedEquipmentType = payload;
      })
      .addCase(getEquipmentTypeByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addCase(putEquipmentTypeByIdThunk.pending, (state, action) => {})
      .addCase(putEquipmentTypeByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedEquipmentType = payload;
        putListData(state.equipmentTypes, payload);
      })
      .addCase(putEquipmentTypeByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        console.log('payload', payload);
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addCase(deleteEquipmentTypeThunk.pending, (state, action) => {})
      .addCase(deleteEquipmentTypeThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;
        deleteDataFromList(state.equipmentTypes, meta?.arg?.param);
      })
      .addCase(deleteEquipmentTypeThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = equipmentTypeSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getEquipmentTypeLoadingStatus = (state: RootState) =>
  state.equipmentType.status;
export const getEquipmentTypes = (state: RootState) =>
  state.equipmentType.equipmentTypes;
export const getSelectedEquipmentType = (state: RootState) =>
  state.equipmentType.selectedEquipmentType;

export default equipmentTypeSlice.reducer;
