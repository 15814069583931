import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {
  deleteDataFromList,
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  approveWorkerThunk,
  createAdminThunk,
  createClientThunk,
  createOrderThunk,
  createWorkerThunk,
  deleteAdminThunk,
  deleteWorkersThunk,
  getAdminByIdThunk,
  getAnalyticsThunk,
  getClientByIdThunk,
  getClientDetailsByIdThunk,
  getWorkerByIdThunk,
  putAdminByIdThunk,
  putClientByIdThunk,
  putWorkerByIdThunk,
  searchAdminsThunk,
  searchClientsThunk,
  searchNotificationsThunk,
  searchWorkersThunk,
} from '../thunks';

// Define a type for the slice state

export interface ClientPayload {
  id: string;
}

export interface WorkerPayload {
  id: string;
}

export interface AdminPayload {
  id: string;
}

export interface NotificationPayload {
  id: string;
  title: string;
  content: string;
  recipient_id: string;
  recipient_types: string[];
  status: string;
  type: string;
}

export interface ListPayload<Member> {
  count: number;
  has_more: boolean;
  list: Member[];
  total: number;
}

interface AdminState {
  admins?: ListPayload<AdminPayload>;
  clients?: ListPayload<ClientPayload>;
  workers?: ListPayload<WorkerPayload>;
  notifications?: ListPayload<NotificationPayload>;
  selectedAdmin?: AdminPayload;
  selectedClient?: ClientPayload;
  selectedClientDetails?: ClientPayload;
  selectedWorker?: WorkerPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: AdminState = {
  admins: undefined,
  clients: undefined,
  workers: undefined,
  notifications: undefined,
  selectedAdmin: undefined,
  selectedClient: undefined,
  selectedWorker: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedAdmin = undefined;
      state.selectedClient = undefined;
      state.selectedClientDetails = undefined;
      state.selectedWorker = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createAdminThunk.pending, (state, action) => {})
      .addCase(createAdminThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.admins, payload);
      })
      .addCase(createAdminThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(createClientThunk.pending, (state, action) => {})
      .addCase(createClientThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.clients, payload);
      })
      .addCase(createClientThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(createWorkerThunk.pending, (state, action) => {})
      .addCase(createWorkerThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.workers, payload);
      })
      .addCase(createWorkerThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(createOrderThunk.pending, (state, action) => {})
      .addCase(createOrderThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.workers, payload);
      })
      .addCase(createOrderThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(getAnalyticsThunk.pending, (state, action) => {})
      .addCase(getAnalyticsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        console.log('getAnalyticsThunk payload', payload);
      })
      .addCase(getAnalyticsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(searchAdminsThunk.pending, (state, action) => {
        state.admins = undefined;
      })
      .addCase(searchAdminsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.admins = payload;
      })
      .addCase(searchAdminsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.admins = undefined;
      })

      .addCase(searchClientsThunk.pending, (state, action) => {
        state.clients = undefined;
      })
      .addCase(searchClientsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.clients = payload;
      })
      .addCase(searchClientsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.clients = undefined;
      })

      .addCase(searchWorkersThunk.pending, (state, action) => {
        state.workers = undefined;
      })
      .addCase(searchWorkersThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.workers = payload;
      })
      .addCase(searchWorkersThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.workers = undefined;
      })

      .addCase(searchNotificationsThunk.pending, (state, action) => {
        state.notifications = undefined;
      })
      .addCase(searchNotificationsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.notifications = payload;
      })
      .addCase(searchNotificationsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.notifications = undefined;
      })

      .addCase(getAdminByIdThunk.pending, (state, action) => {
        state.selectedAdmin = undefined;
      })
      .addCase(getAdminByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedAdmin = payload;
      })
      .addCase(getAdminByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.selectedAdmin = undefined;
      })

      .addCase(getClientByIdThunk.pending, (state, action) => {
        state.selectedClient = undefined;
      })
      .addCase(getClientByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedClient = payload;
      })
      .addCase(getClientByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.selectedClient = undefined;
      })

      .addCase(getClientDetailsByIdThunk.pending, (state, action) => {
        state.selectedClientDetails = undefined;
      })
      .addCase(getClientDetailsByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedClientDetails = payload;
      })
      .addCase(getClientDetailsByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.selectedClientDetails = undefined;
      })

      .addCase(getWorkerByIdThunk.pending, (state, action) => {
        state.selectedWorker = undefined;
      })
      .addCase(getWorkerByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedWorker = payload;
      })
      .addCase(getWorkerByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.selectedWorker = undefined;
      })

      .addCase(putAdminByIdThunk.pending, (state, action) => {
        state.selectedAdmin = undefined;
      })
      .addCase(putAdminByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedAdmin, payload);
        putListData(state.admins, payload);
      })
      .addCase(putAdminByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.selectedAdmin = undefined;
      })

      .addCase(putClientByIdThunk.pending, (state, action) => {
        state.selectedClient = undefined;
      })
      .addCase(putClientByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedClient, payload);
        putListData(state.clients, payload);
      })
      .addCase(putClientByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.selectedClient = undefined;
      })

      .addCase(putWorkerByIdThunk.pending, (state, action) => {
        state.selectedWorker = undefined;
      })
      .addCase(putWorkerByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedWorker, payload);
        putListData(state.workers, payload);
      })
      .addCase(putWorkerByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.selectedWorker = undefined;
      })

      .addCase(approveWorkerThunk.pending, (state, action) => {
        state.selectedWorker = undefined;
      })
      .addCase(approveWorkerThunk.fulfilled, (state, action) => {
        const {payload} = action;
        updateSelectedData(state.selectedWorker, payload);
        putListData(state.workers, payload);
      })
      .addCase(approveWorkerThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.selectedWorker = undefined;
      })

      // DELETE
      .addCase(deleteWorkersThunk.pending, (state, action) => {})
      .addCase(deleteWorkersThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;
        console.log('payload', payload);
        payload?.forEach((obj: any) => {
          deleteDataFromList(state.workers, obj?.id);
        });
      })
      .addCase(deleteWorkersThunk.rejected, (state, action) => {
        const {payload} = action;
        // console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(deleteAdminThunk.pending, (state, action) => {})
      .addCase(deleteAdminThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;
        console.log('payload', payload);
        deleteDataFromList(state.admins, payload?.id);
      })
      .addCase(deleteAdminThunk.rejected, (state, action) => {
        const {payload} = action;
        // console.log('payload', payload);
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = adminSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getAdminLoadingStatus = (state: RootState) => state.admin.status;
export const getAdmins = (state: RootState) => state.admin.admins;
export const getClients = (state: RootState) => state.admin.clients;
export const getWorkers = (state: RootState) => state.admin.workers;
export const getNotifications = (state: RootState) => state.admin.notifications;
export const getSelectedAdmin = (state: RootState) => state.admin.selectedAdmin;
export const getSelectedClient = (state: RootState) =>
  state.admin.selectedClient;
export const getSelectedClientDetails = (state: RootState) =>
  state.admin.selectedClientDetails;
export const getSelectedWorker = (state: RootState) =>
  state.admin.selectedWorker;

export default adminSlice.reducer;
