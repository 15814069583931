import {ApiMethod} from '../axios/axios';

// POST METHODS
export const createEquipmentTypeApi = {
  url: '/equipment-types',
  method: ApiMethod.POST,
};

// GET METHODS
export const getEquipmentTypeByIdApi = {
  url: '/equipment-types',
  method: ApiMethod.GET,
};

export const getAllEquipmentTypesApi = {
  url: '/equipment-types/all',
  method: ApiMethod.GET,
};

export const searchEquipmentTypesApi = {
  url: '/equipment-types/search',
  method: ApiMethod.GET,
};

// PUT METHODS
export const putEquipmentTypeByIdApi = {
  url: '/equipment-types',
  method: ApiMethod.PUT,
};

// DELETE
export const deleteEquipmentTypeApi = {
  url: '/equipment-types',
  method: ApiMethod.DELETE,
};
