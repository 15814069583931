import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  approveWorkerApi,
  assignOrderApi,
  createAdminApi,
  createClientApi,
  createOrderApi,
  createWorkerApi,
  deactivateOrderApi,
  deleteAdminApi,
  deleteWorkersApi,
  exportAccessoryExcelApi,
  exportBrandExcelApi,
  exportClientExcelApi,
  exportEquipmentTypeExcelApi,
  exportManualInvoiceExcelApi,
  exportOrderExcelApi,
  exportWorkerExcelApi,
  getAdminByIdApi,
  getAnalyticsApi,
  getClientByIdApi,
  getOrdersOptionsApi,
  getWorkerByIdApi,
  putAdminByIdApi,
  putClientByIdApi,
  putWorkerByIdApi,
  reactivateOrderApi,
  searchAdminsApi,
  searchClientsApi,
  searchNotificationsApi,
  searchWorkersApi,
  searchWorkersToAssignApi,
} from '../api/adminApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

export const getAnalyticsThunk = createAsyncThunk(
  'admin/getAnalytics',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getAnalyticsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const createAdminThunk = createAsyncThunk(
  'admin/createAdmin',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(createAdminApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const createClientThunk = createAsyncThunk(
  'admin/createClient',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(createClientApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const createWorkerThunk = createAsyncThunk(
  'admin/createWorker',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(createWorkerApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const createOrderThunk = createAsyncThunk(
  'admin/createOrder',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(createOrderApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchClientsThunk = createAsyncThunk(
  'admin/searchClients',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchClientsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchWorkersThunk = createAsyncThunk(
  'admin/searchWorkers',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchWorkersApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const exportClientExcelThunk = createAsyncThunk(
  'admin/exportClientExcel',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(exportClientExcelApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const exportWorkerExcelThunk = createAsyncThunk(
  'admin/exportWorkerExcel',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(exportWorkerExcelApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const exportAccessoryExcelThunk = createAsyncThunk(
  'admin/exportAccessoryExcel',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(exportAccessoryExcelApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const exportManualInvoiceExcelThunk = createAsyncThunk(
  'admin/exportManualInvoiceExcel',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(
        exportManualInvoiceExcelApi,
        payload,
      );
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const exportEquipmentTypeExcelThunk = createAsyncThunk(
  'admin/exportEquipmentTypeExcel',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(
        exportEquipmentTypeExcelApi,
        payload,
      );
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const exportBrandExcelThunk = createAsyncThunk(
  'admin/exportBrandExcel',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(exportBrandExcelApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const exportOrderExcelThunk = createAsyncThunk(
  'admin/exportOrderExcel',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(exportOrderExcelApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchWorkersToAssignThunk = createAsyncThunk(
  'admin/searchWorkersToAssign',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchWorkersToAssignApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getAdminByIdThunk = createAsyncThunk(
  'admin/getAdminById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getAdminByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getClientByIdThunk = createAsyncThunk(
  'admin/getClientById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getClientByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getClientDetailsByIdThunk = createAsyncThunk(
  'admin/getClientDetailsById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getClientByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getWorkerByIdThunk = createAsyncThunk(
  'admin/getWorkerById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getWorkerByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchAdminsThunk = createAsyncThunk(
  'admin/searchAdmins',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchAdminsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getOrdersOptionsThunk = createAsyncThunk(
  'admin/getOrdersOptions',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getOrdersOptionsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchNotificationsThunk = createAsyncThunk(
  'admin/searchNotifications',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchNotificationsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT

export const putClientByIdThunk = createAsyncThunk(
  'admin/putClientById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putClientByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const putWorkerByIdThunk = createAsyncThunk(
  'admin/putWorkerById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putWorkerByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const approveWorkerThunk = createAsyncThunk(
  'admin/approveWorker',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(approveWorkerApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const assignOrderThunk = createAsyncThunk(
  'order/assign',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(assignOrderApi, payload);
      console.log('assignOrderThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const deactivateOrderThunk = createAsyncThunk(
  'order/deactivate',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deactivateOrderApi, payload);
      console.log('deactivateOrderThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const putAdminByIdThunk = createAsyncThunk(
  'order/reactivate',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putAdminByIdApi, payload);
      console.log('putAdminByIdThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const reactivateOrderThunk = createAsyncThunk(
  'order/reactivate',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(reactivateOrderApi, payload);
      console.log('reactivateOrderThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// DELETE
export const deleteWorkersThunk = createAsyncThunk(
  'admin/deleteWorkers',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deleteWorkersApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const deleteAdminThunk = createAsyncThunk(
  'admin/deleteAdmin',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deleteAdminApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
