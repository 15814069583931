import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import {ManualInvoiceType} from '../../enums';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {
  deleteDataFromList,
  prependListData,
  putListData,
  updateSelectedData,
} from '../../utils/apiHelper';
import {
  assignOrderThunk,
  createManualInvoiceThunk,
  deactivateOrderThunk,
  deleteManualInvoiceThunk,
  getOrderByIdThunk,
  reactivateOrderThunk,
  searchManualInvoicesThunk,
  searchOrdersThunk,
  sendManualInvoiceThunk,
} from '../thunks';

// Define a type for the slice state

export interface OrderPayload {
  id: string;
  type: string;
  name: string;
}

interface ItemPayload {
  name: string;
  price: number;
  quantity: number;
  total: number;
}

export interface ManualInvoicePayload {
  ref: string;
  recipient_name: string;
  recipient_mobile_number: string;
  items: ItemPayload[];
  amount: number;
  document_url: string;
  created_by: string;
  type: ManualInvoiceType;
  is_sent: boolean;
}

interface OrderState {
  orders?: ListPayload<OrderPayload>;
  selectedOrder?: OrderPayload;
  manualInvoices?: ListPayload<ManualInvoicePayload>;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: OrderState = {
  orders: undefined,
  selectedOrder: undefined,
  manualInvoices: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedOrder = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createManualInvoiceThunk.pending, (state, action) => {})
      .addCase(createManualInvoiceThunk.fulfilled, (state, action) => {
        const {payload} = action;
        if (!payload?.id) return;
        prependListData(state.manualInvoices, payload);
      })
      .addCase(createManualInvoiceThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })
      // .addCase(createServiceThunk.pending, (state, action) => {})
      // .addCase(createServiceThunk.fulfilled, (state, action) => {
      //   const {payload} = action;
      //   prependListData(state.orders, payload);
      // })
      // .addCase(createServiceThunk.rejected, (state, action) => {
      //   const {payload} = action;
      //   if (payload) {
      //     toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
      //   }
      // })

      .addCase(searchManualInvoicesThunk.pending, (state, action) => {})
      .addCase(searchManualInvoicesThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.manualInvoices = payload;
      })
      .addCase(searchManualInvoicesThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.manualInvoices = undefined;
      })

      .addCase(searchOrdersThunk.pending, (state, action) => {})
      .addCase(searchOrdersThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.orders = payload;
      })
      .addCase(searchOrdersThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
        state.orders = undefined;
      })

      .addCase(getOrderByIdThunk.pending, (state, action) => {
        state.selectedOrder = undefined;
      })
      .addCase(getOrderByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedOrder = payload;
      })
      .addCase(getOrderByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })
      //  PUT
      .addCase(sendManualInvoiceThunk.pending, (state, action) => {})
      .addCase(sendManualInvoiceThunk.fulfilled, (state, action) => {
        const {payload} = action;
        // if (state.selectedOrder?.id === payload?.id) {
        //   state.selectedOrder = payload;
        // }
        putListData(state.manualInvoices, payload);
      })
      .addCase(sendManualInvoiceThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(assignOrderThunk.pending, (state, action) => {})
      .addCase(assignOrderThunk.fulfilled, (state, action) => {
        const {payload} = action;
        if (state.selectedOrder?.id === payload?.id) {
          state.selectedOrder = payload;
        }
        putListData(state.orders, payload);
      })
      .addCase(assignOrderThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(deactivateOrderThunk.pending, (state, action) => {})
      .addCase(deactivateOrderThunk.fulfilled, (state, action) => {
        const {payload} = action;
        if (state.selectedOrder?.id === payload?.id) {
          state.selectedOrder = payload;
        }
        putListData(state.orders, payload);
      })
      .addCase(deactivateOrderThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      .addCase(reactivateOrderThunk.pending, (state, action) => {})
      .addCase(reactivateOrderThunk.fulfilled, (state, action) => {
        const {payload} = action;
        if (state.selectedOrder?.id === payload?.id) {
          state.selectedOrder = payload;
        }
        putListData(state.orders, payload);
      })
      .addCase(reactivateOrderThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
        }
      })

      // .addCase(putServiceThunk.pending, (state, action) => {})
      // .addCase(putServiceThunk.fulfilled, (state, action) => {
      //   const {payload} = action;
      //   state.selectedService = payload;
      //   putListData(state.services, payload);
      // })
      // .addCase(putServiceThunk.rejected, (state, action) => {
      //   const {payload} = action;
      //   console.log('payload', payload);
      //   if (payload) {
      //     toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
      //   }
      // })

      // .addCase(deleteServiceByIdThunk.pending, (state, action) => {})
      // .addCase(deleteServiceByIdThunk.fulfilled, (state, action) => {
      //   const {payload, meta} = action;
      //   if (payload?.deletedCount === 1) {
      //     deleteDataFromList(state.services, meta?.arg?.param);
      //   }
      // })
      // .addCase(deleteServiceByIdThunk.rejected, (state, action) => {
      //   const {payload} = action;
      //   // console.log('payload', payload);
      //   if (payload) {
      //     toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
      //   }
      // })

      .addCase(deleteManualInvoiceThunk.pending, (state, action) => {})
      .addCase(deleteManualInvoiceThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;
        deleteDataFromList(state.manualInvoices, meta?.arg?.param);
      })
      .addCase(deleteManualInvoiceThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = orderSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getOrderLoadingStatus = (state: RootState) => state.order.status;
export const getOrders = (state: RootState) => state.order.orders;
export const getManualInvoices = (state: RootState) =>
  state.order.manualInvoices;
export const getSelectedOrder = (state: RootState) => state.order.selectedOrder;

export default orderSlice.reducer;
