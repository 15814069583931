import {ApiMethod} from '../axios/axios';

// POST METHODS
export const createBrandApi = {
  url: '/brands',
  method: ApiMethod.POST,
};

// GET METHODS
export const getBrandByIdApi = {
  url: '/brands',
  method: ApiMethod.GET,
};

export const getAllBrandsApi = {
  url: '/brands/all',
  method: ApiMethod.GET,
};

export const searchBrandsApi = {
  url: '/brands/search',
  method: ApiMethod.GET,
};

// PUT METHODS
export const putBrandByIdApi = {
  url: '/brands',
  method: ApiMethod.PUT,
};

// DELETE
export const deleteBrandApi = {
  url: '/brands',
  method: ApiMethod.DELETE,
};
