import {
  AdminRight,
  HighlightStatus,
  ManualInvoiceType,
  MembershipStatus,
  NotificationType,
  OrderStatus,
  PostRecipientType,
  PostStatus,
  ProductType,
  ServiceType,
} from '../enums';

export const HK_DISTRICTS = [
  '中西區',
  '東區',
  '南區',
  '深水埗區',
  '九龍城區',
  '黃大仙區',
  '觀塘區',
  '油尖旺區',
  '葵青區',
  '荃灣區',
  '屯門區',
  '元朗區',
  '北區',
  '大埔區',
  '沙田區',
  '西貢區',
  '離島區',
  '灣仔區',
];

export const PHONE_DISTRICTS = [{label: '🇭🇰 +852', value: '+852'}];

export const IDENTITY_PROOF_TYPE = ['員工証'];

export const SERVICE_CATEGORIES = ['類別1', '類別2', '類別3'];

export const WORKER_POLICY = `
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXX

XXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXX
  1. XXXXXXXXXXXXXXXXXXXXXXXX
  2. XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  3. XXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
XXXXXXXXXXXXXXXXXXXXXXXXXXXXX
`;

export const HIGHLIGHT_STATUS = [
  {
    label: '顯示',
    value: HighlightStatus.ACTIVE,
  },
  {
    label: '隱藏',
    value: HighlightStatus.INACTIVE,
  },
];

export const CLIENT_MEMBERSHIP_STATUS = [
  {
    label: '有效',
    value: MembershipStatus.ACTIVE,
  },
  {
    label: '封鎖',
    value: MembershipStatus.DEACTIVATED,
  },
];

export const WORKER_MEMBERSHIP_STATUS = [
  {
    label: '有效',
    value: MembershipStatus.ACTIVE,
  },
  {
    label: '等待審批',
    value: MembershipStatus.PENDING_APPROVAL,
  },
  {
    label: '審批失敗',
    value: MembershipStatus.DISAPPROVED,
  },
  {
    label: '封鎖',
    value: MembershipStatus.DEACTIVATED,
  },
];

export const SERVICE_SETTING_TYPES = [
  {label: '產品', value: ServiceType.PRODUCT},
  // {label: '產品類別', value: ServiceType.PRODUCT_TYPE},
  {label: '情況', value: ServiceType.ISSUE},
];

export const ORDER_STATUS = [
  {
    label: '待支付出勤費用',
    value: OrderStatus.PENDING_PAYMENT,
  },
  {
    label: '出勤費用支付失敗',
    value: OrderStatus.PAYMENT_FAILURE,
  },
  {
    label: '已付出勤費正在等待審批',
    value: OrderStatus.PAYMENT_PROCESSING,
  },
  {
    label: '等待師父接單',
    value: OrderStatus.PENDING_ACCEPT,
  },
  {
    label: '訂單已拒絕',
    value: OrderStatus.REJECTED,
  },
  {
    label: '等待預約上門時間',
    value: OrderStatus.PENDING_COMMUNICATION,
  },
  {
    label: '等待客戶確認維修日期',
    value: OrderStatus.PENDING_CONFIRM_DATE,
  },
  {
    label: '等待師傅上門',
    value: OrderStatus.PENDING_DELIVERY,
  },
  {
    label: '師傅正在路上',
    value: OrderStatus.ON_THE_WAY,
  },
  {
    label: '師傅已到達',
    value: OrderStatus.WORKER_ARRIVED,
  },
  {
    label: '師傅已建立帳單，等待客戶確認帳單',
    value: OrderStatus.PENDING_CONFIRM_PRICE,
  },
  {
    label: '等待客戶付款',
    value: OrderStatus.PENDING_FINAL_PAYMENT,
  },
  {
    label: '客戶付款失敗',
    value: OrderStatus.FINAL_PAYMENT_FAILURE,
  },
  {
    label: '客戶已付款項正在等待審批',
    value: OrderStatus.FINAL_PAYMENT_PROCESSING,
  },
  // {
  //   label: '封鎖',
  //   value: OrderStatus.FINAL_PAYMENT_DISPUTE,
  // },
  {
    label: '已完工，等待客戶對師傅作出評價',
    value: OrderStatus.PENDING_REVIEW,
  },
  {
    label: '已完工',
    value: OrderStatus.ENDED,
  },
];

export const WORKER_REGISTER_PRODUCT_TYPE_MAPPING = [
  {label: '電', value: ProductType.ELECTRIC},
  {label: '煤氣', value: ProductType.GAS},
  // {label: '兩者皆是', value: ProductType.BOTH},
  {label: '冷凍', value: ProductType.FROST},
  {label: '鮮風抽氣', value: ProductType.AIR},
];

export const ORDER_STATUS_MAPPING = [
  {
    label: '待支付出勤費用',
    value: OrderStatus.PENDING_PAYMENT,
  },
  {
    label: '已付出勤費正在等待審批',
    value: OrderStatus.PAYMENT_PROCESSING,
  },
  {
    label: '出勤費用支付失敗',
    value: OrderStatus.PAYMENT_FAILURE,
  },
  {
    label: '已付出勤費，等待師父接單',
    value: OrderStatus.PENDING_ACCEPT,
  },
  {
    label: '預約上門時間，等待師傅提出時間',
    value: OrderStatus.PENDING_CONFIRM_DATE,
  },
  {
    label: '預約上門時間，等待客戶接受時間',
    value: OrderStatus.PENDING_COMMUNICATION,
  },
  {
    label: '已確認維修日期，等待師傅上門',
    value: OrderStatus.PENDING_DELIVERY,
  },
  {
    label: '師傅正在路上',
    value: OrderStatus.ON_THE_WAY,
  },
  {
    label: '師傅已到達',
    value: OrderStatus.WORKER_ARRIVED,
  },
  {
    label: '師傅已建立帳單，等待客戶確認帳單',
    value: OrderStatus.PENDING_CONFIRM_PRICE,
  },
  {
    label: '完工，等待客戶付款',
    value: OrderStatus.PENDING_FINAL_PAYMENT,
  },
  {
    label: '完工，付款失敗，等待重新付款',
    value: OrderStatus.FINAL_PAYMENT_FAILURE,
  },
  {
    label: '完工，已付款項正在等待審批',
    value: OrderStatus.FINAL_PAYMENT_PROCESSING,
  },
  {
    label: '完工，等待職員聯絡客戶重新議價',
    value: OrderStatus.FINAL_PAYMENT_DISPUTE,
  },
  {label: '完工，已付款，等待客戶評價', value: OrderStatus.PENDING_REVIEW},
  {label: '完工，客戶已付款', value: OrderStatus.ENDED},
  {label: '已被停用', value: OrderStatus.DEACTIVATED},
];

export const ADMIN_RIGHT = [
  {
    label: '超級管理員',
    value: AdminRight.SUPER_ADMIN,
  },
  {
    label: '管理員',
    value: AdminRight.ADMIN,
  },
  {
    label: '僅限查看',
    value: AdminRight.VIEW_ONLY,
  },
];

export const POST_RECIPIENT_TYPE = [
  {
    label: '所有',
    value: PostRecipientType.ALL,
  },
  {
    label: '客戶',
    value: PostRecipientType.CLIENT,
  },
  {
    label: '師傅',
    value: PostRecipientType.WORKER,
  },
  {
    label: '指定',
    value: PostRecipientType.SELECTION,
  },
];

export const POST_STATUS = [
  {
    label: '顯示',
    value: PostStatus.ACTIVE,
  },
  {
    label: '隱藏',
    value: PostStatus.HIDDEN,
  },
];

export const NOTIFICATION_TYPE = [
  {
    label: '所有',
    value: '',
  },
  {
    label: '非緊急',
    value: NotificationType.LOW_PRIORITY,
  },
  {
    label: '一般',
    value: NotificationType.NORMAL,
  },
  {
    label: '緊急',
    value: NotificationType.URGENT,
  },
];

export const MANUAL_INVOICE_TYPE = [
  {
    label: '發票',
    value: ManualInvoiceType.INVOICE,
  },
  {
    label: '收據',
    value: ManualInvoiceType.RECEIPT,
  },
];
