import './index.module.scss';
import './translations/i18n';

// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import Favicon from 'react-favicon';
import {Helmet} from 'react-helmet';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import {IMAGES} from './constants';
import Context from './context';
// import {ToastProvider} from './context/ToastContext';
// import * as serviceWorker from './serviceWorker';
import {store} from './store';
import {isLocalhost} from './utils';

if (!isLocalhost) {
  console.log = function () {};
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  // <React.StrictMode>
  <>
    <Helmet>
      <title>Health Kitchen</title>
    </Helmet>
    <Favicon url={IMAGES.favicon} />
    <Provider store={store}>
      <BrowserRouter>
        <Context>
          {/* <ToastProvider> */}
          <App />
          {/* </ToastProvider> */}
        </Context>
      </BrowserRouter>
    </Provider>
    ,
  </>,
  // </React.StrictMode>
  // document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
