export enum SOCKET_ROOMS {
  ADMIN = 'admin-room',
}

export enum SOCKET_EVENT_ORDERS {
  JOIN_ORDER_ROOMS = 'JOIN_ORDER_ROOMS',
  LEAVE_ORDER_ROOMS = 'LEAVE_ORDER_ROOMS',
  WORKER_LOCATION = 'WORKER_LOCATION',
  ON_DELIVERY = 'ON_DELIVERY',
  ON_ARRIVAL = 'ON_ARRIVAL',
  WORKER_CREATED_BILL = 'WORKER_CREATED_BILL',
  CLIENT_CONFIRMED_BILL = 'CLIENT_CONFIRMED_BILL',
  CLIENT_ORDER_PENDING_GRAB = 'CLIENT_ORDER_PENDING_GRAB',
  REVIEW_PENDING_READ = 'REVIEW_PENDING_READ',
  WORKER_PAY_PENDING_PAY = 'WORKER_PAY_PENDING_PAY',
}

export enum SOCKET_EVENT_MEMBERS {
  WORKER_REGISTER = 'WORKER_REGISTER',
  CLIENT_REGISTER = 'CLIENT_REGISTER',
}

export enum SOCKET_EVENT_PAYMENTS {
  CLIENT_MANUAL_PAYMENT = 'CLIENT_MANUAL_PAYMENT',
}
