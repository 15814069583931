import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  createManualInvoiceApi,
  deleteManualInvoiceApi,
  searchManualInvoicesApi,
  sendManualInvoicesApi,
} from '../api';
import {
  getOrderByIdApi,
  getOrderCalendarInfoApi,
  searchOrdersApi,
} from '../api/orderApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

// POST
export const createManualInvoiceThunk = createAsyncThunk(
  'order/createManualInvoice',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(createManualInvoiceApi, payload);
      console.log('createManualInvoiceThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// GET
export const searchManualInvoicesThunk = createAsyncThunk(
  'order/searchManualInvoices',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchManualInvoicesApi, payload);
      return {...response?.data?.data, type: payload?.query?.type || ''};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchOrdersThunk = createAsyncThunk(
  'order/searchOrders',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchOrdersApi, payload);
      return {...response?.data?.data, type: payload?.query?.type || ''};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getOrderByIdThunk = createAsyncThunk(
  'order/getOrderById',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getOrderByIdApi, payload);
      console.log('getOrderByIdThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const sendManualInvoiceThunk = createAsyncThunk(
  'order/sendManualInvoice',
  async (payload: UseRequestPayload = {}, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(sendManualInvoicesApi, payload);
      console.log('sendManualInvoiceThunk response', response);
      // The value we return becomes the `fulfilled` action payload
      const data = response?.data?.data;
      return data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// DELETE
export const deleteManualInvoiceThunk = createAsyncThunk(
  'order/deleteManualInvoice',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deleteManualInvoiceApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getOrderCalendarInfoThunk = createAsyncThunk(
  'order/getOrderCalendarInfo',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getOrderCalendarInfoApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
