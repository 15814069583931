import {ApiMethod} from '../axios/axios';

// POST
export const createManualInvoiceApi = {
  url: '/manual-invoices',
  method: ApiMethod.POST,
};

// GET
export const searchManualInvoicesApi = {
  url: '/manual-invoices/search',
  method: ApiMethod.GET,
};

// PUT
export const sendManualInvoicesApi = {
  url: '/manual-invoices/send',
  method: ApiMethod.PUT,
};

// DELETE
export const deleteManualInvoiceApi = {
  url: '/manual-invoices',
  method: ApiMethod.DELETE,
};
