import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {io, ManagerOptions, Socket, SocketOptions} from 'socket.io-client';

// import Peer from "peerjs"
import ToastContent from '../components/ToastContent';
import {
  SOCKET_EVENT_MEMBERS,
  SOCKET_EVENT_ORDERS,
  SOCKET_EVENT_PAYMENTS,
} from '../enums';
import {useAppDispatch, useAppSelector} from '../hooks/store';
import {toastAction} from '../hooks/toast';
import {useCurrentUser} from '../hooks/useCurrentUser';
import {getAccessToken, getUser} from '../services/reducers/authSlice';
import {store} from '../store';
// import {store} from '../store';
import {decryptToken, moment} from '../utils';

const socketOptions: Partial<SocketOptions & ManagerOptions> = {
  transports: ['websocket'],
  autoConnect: false,
};

const socket = io(process.env.REACT_APP_WEBSOCKET_URL || '', socketOptions);

export const SocketContext = createContext<any>(undefined);

export const useSocket = () => useContext(SocketContext);

export function SocketProvider(props: any) {
  const accessToken = useAppSelector(getAccessToken);
  const userInfo = useCurrentUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [workerCoords, setWorkerCoords] = useState<any>({});
  // SOCKET EMIT FUNCTIONS

  // const socketJoinRoom = () => {
  //   const currentRoomId = store.getState().room?.currentRoom?.id;
  //   if (!currentRoomId) return;
  //   const payload = {room_id: currentRoomId};
  //   socket.emit('room-join', payload);
  // };

  // const socketUnjoinRoom = () => {
  //   const currentRoomId = store.getState().room?.currentRoom?.id;
  //   if (!currentRoomId) return;
  //   const payload = {room_id: currentRoomId};
  //   socket.emit('room-unjoin', payload);
  //   dispatch(socketLeftRoom());
  //   // const stream: MediaStream | undefined = window.localStream;
  //   // if (stream) {
  //   //   stream.getTracks().forEach(track => track.stop());
  //   // }
  // };

  const socketJoinOrderRoom = useCallback((payload: {order_id: string}) => {
    socket.emit(SOCKET_EVENT_ORDERS.JOIN_ORDER_ROOMS, payload);
  }, []);

  const socketLeaveOrderRoom = useCallback((payload: {order_id: string}) => {
    socket.emit(SOCKET_EVENT_ORDERS.LEAVE_ORDER_ROOMS, payload);
    setWorkerCoords({});
  }, []);

  // INIT SOCKET EVENTS
  const initSocket = (socket: Socket) => {
    console.log('initSocket');
    socket.on(
      SOCKET_EVENT_ORDERS.WORKER_LOCATION,
      (payload: {latitude: number; longitude: number; order_id: string}) => {
        console.log('SOCKET', SOCKET_EVENT_ORDERS.WORKER_LOCATION, payload);
        const {order_id, latitude, longitude} = payload || {};
        if (store.getState()?.order?.selectedOrder?.id === order_id) {
          if (latitude && longitude) {
            setWorkerCoords({
              lat: latitude,
              lng: longitude,
              order_id,
            });
          }
        }
      },
    );

    socket.on('socket-connect', (socket_id: string) => {
      console.log('socket connected');
      // console.log('socket connected', socket_id);
    });

    socket.on(
      SOCKET_EVENT_MEMBERS.WORKER_REGISTER,
      ({worker_id, mobile_number}) => {
        if (!worker_id) return;
        toastAction({
          message: '新師傅註冊',
          label: '前往批核',
          caption: `☎️: ${mobile_number}`,
          onConfirm: () => {
            navigate(`members/workers/${worker_id}`);
          },
        });
      },
    );

    socket.on(
      SOCKET_EVENT_PAYMENTS.CLIENT_MANUAL_PAYMENT,
      (payload: {
        client_id: string;
        mobile_number: string;
        transaction_id: string;
        transaction_ref: string;
        order_ref: string;
        order_id: string;
        method: string;
      }) => {
        console.log(
          SOCKET_EVENT_PAYMENTS.CLIENT_MANUAL_PAYMENT,
          'payload',
          payload,
        );
        if (!payload.transaction_id) return;
        toastAction({
          message: '客戶上傳付款憑證',
          label: '前往處理',
          caption: `#${payload.transaction_ref}`,
          onConfirm: () => {
            navigate(`orders/payments/${payload.transaction_id}`);
          },
        });
      },
    );

    socket.on(
      SOCKET_EVENT_ORDERS.CLIENT_ORDER_PENDING_GRAB,
      (payload: {order_id: string; delivery_payment_at: string}) => {
        // console.log(
        //   SOCKET_EVENT_ORDERS.CLIENT_ORDER_PENDING_GRAB,
        //   'payload',
        //   payload,
        // );
        if (!payload.order_id || !payload.delivery_payment_at) return;
        toastAction({
          message: '訂單待搶',
          label: '前往處理',
          // caption: `☎️: ${mobile_number}`,
          onConfirm: () => {
            navigate(`orders/details/${payload.order_id}`);
          },
        });
      },
    );

    socket.on(
      SOCKET_EVENT_ORDERS.REVIEW_PENDING_READ,
      (payload: {
        review_id: string;
        order_ref: string;
        reviewer_name: string;
      }) => {
        // console.log(
        //   SOCKET_EVENT_ORDERS.REVIEW_PENDING_READ,
        //   'payload',
        //   payload,
        // );
        if (
          !payload?.review_id ||
          !payload?.order_ref ||
          !payload?.reviewer_name
        )
          return;
        toastAction({
          message: '售後評價待處理',
          caption: `${payload.reviewer_name}發表了售後評價`,
          label: '前往處理',
          onConfirm: () => {
            navigate(`orders/reports/${payload.review_id}`);
          },
        });
      },
    );

    socket.on(
      SOCKET_EVENT_ORDERS.WORKER_PAY_PENDING_PAY,
      (payload: {
        order_id: string;
        order_ref: string;
        worker_name: string;
        confirmed_bill_at: string;
      }) => {
        // console.log(
        //   SOCKET_EVENT_ORDERS.WORKER_PAY_PENDING_PAY,
        //   'payload',
        //   payload,
        // );
        if (
          !payload?.order_id ||
          !payload?.order_ref ||
          !payload?.worker_name ||
          !payload?.confirmed_bill_at
        )
          return;

        const daysAfterConfirmedBill = moment(payload.confirmed_bill_at).diff(
          moment(),
          'day',
        );

        toastAction({
          message: `訂單${payload?.order_ref}待發放報酬`,
          caption: `帳單確認後還未發放報酬`,
          label: '前往發放報酬',
          onConfirm: () => {
            navigate(`pays/details/${payload.order_id}`);
          },
        });
      },
    );

    // SOCKET WEBRTC

    // socket.on(
    // 	"room-updateOnlineList",
    // 	(payload: { room_id: string; data: any }) => {
    // 		console.log("SOCKET room-join", payload)
    // 		const { room_id, data } = payload
    // 		if (!room_id || !data) return
    // 		const currentRoomId = store.getState().room?.currentRoom?.id
    // 		if (currentRoomId === room_id) {
    // 			dispatch(socketUpdateOnlineList(data))
    // 		}
    // 	}
    // )

    // socket.on(
    // 	"room-join",
    // 	(payload: { room_id: string; user: any; socket_id: string }) => {
    // 		console.log("SOCKET room-join", payload)
    // 		const { room_id, user, socket_id } = payload
    // 		if (!room_id || !user || !socket_id) return
    // 		const currentRoomId = store.getState().room?.currentRoom?.id
    // 		if (socket_id !== myPeer?.id) {
    // 			myPeer?.listAllPeers((peerIds) => {
    // 				console.log("peerIdspeerIds", peerIds, socket_id)
    // 				// if (!peerIds.includes(socket_id)) {
    // 				const conn = myPeer?.connect(socket_id)
    // 				conn?.on("open", () => {
    // 					conn.on("data", (data) => {
    // 						console.log(data)
    // 						if (data?.type === "text_message") {
    // 							toast.info(data.data)
    // 						}
    // 						if (data?.type === "change_current_item") {
    // 							dispatch(changeCurrentItem(data.data))
    // 						}
    // 					})
    // 				})
    // 			})
    // 		}

    // 		if (currentRoomId === room_id && userInfo?.id !== user.id) {
    // 			dispatch(socketUserOnline(user))
    // 		}
    // 	}
    // )

    // socket.on(
    // 	"room-unjoin",
    // 	(payload: { room_id: string; user: any; socket_id: string }) => {
    // 		console.log("SOCKET room-unjoin", payload)
    // 		const { room_id, user, socket_id } = payload
    // 		if (!room_id || !user || !socket_id) return
    // 		const currentRoomId = store.getState().room?.currentRoom?.id
    // 		if (currentRoomId === room_id) {
    // 			dispatch(socketUserOffline(user))
    // 		}
    // 	}
    // )

    // socket.on("room-requestStream", (payload: RoomRequestStreamPayload) => {
    // 	console.log("SOCKET room-requestStream", payload)
    // 	const { room_id, audience_socket_id } = payload
    // 	if (!room_id || !audience_socket_id) return
    // 	//@ts-ignore
    // 	const streamData = window.localStream
    // 	if (!streamData) return
    // 	const currentRoomId = store.getState().room?.currentRoom?.id

    // 	if (currentRoomId === room_id) {
    // 		//@ts-ignore
    // 		const stream = window.localStream
    // 		if (stream) {
    // 			myPeer?.call(audience_socket_id, stream)
    // 		}
    // 	}
    // })

    // socket.on("room-startStream", (payload: ReceiveStreamPayload) => {
    // 	const currentRoomId = store.getState().room?.currentRoom?.id
    // 	console.log("SOCKET startStream", payload)
    // 	if (currentRoomId && currentRoomId === payload.room_id) {
    // 		const displayName = payload?.host_info?.display_name
    // 		if (displayName) {
    // 			toast.info(displayName + "started a stream")
    // 		}
    // 	}
    // })

    // socket.on(
    // 	"room-endStream",
    // 	(payload: { room_id: string; host_socket_id: string }) => {
    // 		const currentRoomId = store.getState().room?.currentRoom?.id
    // 		console.log("SOCKET room-endStream", payload)
    // 		if (currentRoomId && currentRoomId === payload.room_id) {
    // 			//@ts-ignore
    // 			window.localStream = undefined
    // 			//@ts-ignore
    // 			otherCanvasRef.current.srcObject = undefined
    // 			if (payload?.host_socket_id !== myPeer?.id) {
    // 				toast.info("Host has stopped the stream")
    // 			}
    // 		}
    // 	}
    // )

    //SOCKET EVENT ITEM
    // socket.on(
    // 	"eventItem-addItem",
    // 	(payload: { room_id: string; data: any }) => {
    // 		const currentRoomId = store.getState().room?.currentRoom?.id
    // 		console.log("eventItem-addItem", payload)
    // 		if (payload?.data && currentRoomId === payload?.room_id) {
    // 			dispatch(socketAddEventItem(payload.data))
    // 		}
    // 	}
    // )

    // socket.on(
    // 	"eventItem-assign",
    // 	(payload: { room_id: string; data: any }) => {
    // 		const currentRoomId = store.getState().room?.currentRoom?.id
    // 		console.log("eventItem-assign", payload)
    // 		if (payload?.data && currentRoomId === payload?.room_id) {
    // 			dispatch(socketAssignEventItem(payload.data))
    // 		}
    // 	}
    // )

    // SOCKER NEW MESSAGE
    // socket.on("roomMessage-new", (payload: any) => {
    // 	console.log("roomMessage-new", payload)
    // 	const currentRoomId = store.getState().room?.currentRoom?.id
    // 	if (payload?.data && currentRoomId === payload?.room_id) {
    // 		dispatch(socketNewMessage(payload))
    // 	}
    // })
  };

  function connect(token: string) {
    if (!token) return;
    socket.auth = {token: token};
    socket.connect();
    initSocket(socket);
  }

  function disconnect() {
    // setStartedStream(false)
    socket.disconnect();
    socket.off();
    // myPeer?.disconnect()
    // myPeer?.destroy()
    // myPeer = undefined
  }

  useEffect(() => {
    if (accessToken) {
      const decrytpedToken = decryptToken(
        accessToken || '',
        process.env.REACT_TOKEN_SECRET || '',
      );
      if (decrytpedToken) {
        connect(decrytpedToken);
      }
    } else {
      disconnect();
    }
  }, [accessToken]);

  useEffect(() => {
    socket.on('disconnect', reason => {
      console.log('disconnect', reason);
    });
    return () => {
      socket.off();
    };
  }, []);

  const values = {
    // myPeer,
    socket,
    connect,
    disconnect,
    socketJoinOrderRoom,
    socketLeaveOrderRoom,
    workerCoords,
    // myCanvasRef,
    // otherCanvasRef,
    // getCanvasMedia,
    // getVideoMedia,
    // startStream,
    // reloadStream,
    // resetVideo,
    // socketRequestStream,
    // disconnectPeers,
    // requestPip,
    // socketJoinRoom,
    // socketUnjoinRoom,
    // startedStream,
    // streamType,
    // sendWebRtcMsg,
  };

  return (
    <SocketContext.Provider value={values}>
      {props.children}
    </SocketContext.Provider>
  );
}
