import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  createPostApi,
  deletePostByIdApi,
  getPostByIdApi,
  getSelectionOptionsApi,
  putPostByIdApi,
  searchPostsApi,
} from '../api';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';
// POST

export const createPostThunk = createAsyncThunk(
  'post/createPost',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(createPostApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// GET
export const getPostByIdThunk = createAsyncThunk(
  'post/getPostById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getPostByIdApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchPostsThunk = createAsyncThunk(
  'post/searchPosts',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchPostsApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getSelectionOptionsThunk = createAsyncThunk(
  'post/getSelectionOptions',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getSelectionOptionsApi, payload);
      return {...response?.data?.data};
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const putPostByIdThunk = createAsyncThunk(
  'post/putPostById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putPostByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// DELETE
export const deletePostByIdThunk = createAsyncThunk(
  'post/deletePostById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deletePostByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
