export const APP_NAME = process.env.REACT_APP_APP_NAME || 'Health Kitchen';

export const fetchStatus = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  FAILED: 'FAILED',
} as const;

export enum FETCH_STATUS {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  FAILED = 'FAILED',
}

export const pageNames = {
  APP_CONFIG: 'APP管理',
  APP_CLIENT_QA: '客戶常見問題',
  APP_WORKER_QA: '師傅常見問題',
  APP_ABOUT_US: '關於我們',
  APP_POST: '通告',
  APP_NOTIFICATION: '通知',
  CATEGORIES_BRANDS: '產品品牌',
  CATEGORIES_EQUIPMENTS: '設備',
  CATEGORIES_EQUIPMENT_TYPES: '設備類別',
  CATEGORIES_ACCESSORIES: '零件',
  CATEGORIES_SERVICES: '維修類別/項目',
  PAYS: '報酬',
  PAY_DETAILS: '報酬詳細',
  ORDERS_DETAILS: '訂單詳情',
  ORDERS_CALENDAR: '訂單詳情日曆',
  ORDERS_SCHEDULES: '師傅更表',
  ORDERS_PAYMENTS: '維修款項',
  ORDERS_REVIEWS: '訂單評論',
  ORDERS_REPORT: '售後服務評價',
  ORDERS_INVOICES: '票據',
  MEMBERS_CLIENTS: '客戶',
  MEMBERS_WORKERS: '師傅',
  HIGHLIGHT_PROMOTION: '推廣',
  HIGHLIGHT_CONSTRUCTION: '工程展示',
  HIGHLIGHT_EQUIPMENTS: '設備展示',
  HIGHLIGHT_ACCESSORIES: '零件倉展示',
  ANALYTICS_ORDER: '訂單統計',
  ANALYTICS_WORKER: '師傅統計',
  ANALYTICS_CLIENT: '客戶統計',
  ANALYTICS: '統計',
  CONSOLE_USER: '後台用戶',
};

export const ACCESS_TOKEN_KEY = 'health_kitchen_token';

export const STORAGE_KEY = {
  ACCESS_TOKEN_KEY: 'health_kitchen_token',
  AUDIO_MUTED: 'health_kitchen_audio_muted',
};

export type LoadingStatus = keyof typeof fetchStatus;
