import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {toast} from 'react-toastify';

import {FETCH_STATUS} from '../../constants/app';
import type {RootState} from '../../store';
import {ListPayload} from '../../types';
import {
  deleteDataFromList,
  prependListData,
  putListData,
} from '../../utils/apiHelper';
import {
  createBrandThunk,
  deleteBrandThunk,
  getBrandByIdThunk,
  putBrandByIdThunk,
  searchBrandsThunk,
} from '../thunks/brandThunk';

// Define a type for the slice state

export interface BrandPayload {
  id: string;
  name: string;
}

interface BrandState {
  brands?: ListPayload<BrandPayload>;
  selectedBrand?: BrandPayload;
  status: FETCH_STATUS;
  errorMsg?: string;
}

const initialState: BrandState = {
  brands: undefined,
  selectedBrand: undefined,
  status: FETCH_STATUS.IDLE,
  errorMsg: undefined,
};

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    resetSelections: (state, action) => {
      state.selectedBrand = undefined;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createBrandThunk.pending, (state, action) => {})
      .addCase(createBrandThunk.fulfilled, (state, action) => {
        const {payload} = action;
        prependListData(state.brands, payload);
      })
      .addCase(createBrandThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addCase(searchBrandsThunk.pending, (state, action) => {})
      .addCase(searchBrandsThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.brands = payload;
      })
      .addCase(searchBrandsThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
        state.brands = undefined;
      })

      .addCase(getBrandByIdThunk.pending, (state, action) => {})
      .addCase(getBrandByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedBrand = payload;
      })
      .addCase(getBrandByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addCase(putBrandByIdThunk.pending, (state, action) => {})
      .addCase(putBrandByIdThunk.fulfilled, (state, action) => {
        const {payload} = action;
        state.selectedBrand = payload;
        putListData(state.brands, payload);
      })
      .addCase(putBrandByIdThunk.rejected, (state, action) => {
        const {payload} = action;
        console.log('payload', payload);
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addCase(deleteBrandThunk.pending, (state, action) => {})
      .addCase(deleteBrandThunk.fulfilled, (state, action) => {
        const {payload, meta} = action;
        deleteDataFromList(state.brands, meta?.arg?.param);
      })
      .addCase(deleteBrandThunk.rejected, (state, action) => {
        const {payload} = action;
        if (payload) {
          if (payload) {
            toast.error(isArray(payload) ? payload[0] : payload || 'ERROR');
          }
        }
      })

      .addMatcher(isFulfilled, (state, action) => {
        state.status = FETCH_STATUS.IDLE;
      })
      .addMatcher(isPending, (state, action) => {
        state.status = FETCH_STATUS.LOADING;
      })
      .addMatcher(isRejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
      });
  },
});

export const {resetSelections} = brandSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getBrandLoadingStatus = (state: RootState) => state.brand.status;
export const getBrands = (state: RootState) => state.brand.brands;
export const getSelectedBrand = (state: RootState) => state.brand.selectedBrand;

export default brandSlice.reducer;
