import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  createEquipmentTypeApi,
  deleteEquipmentTypeApi,
  getAllEquipmentTypesApi,
  getEquipmentTypeByIdApi,
  putEquipmentTypeByIdApi,
  searchEquipmentTypesApi,
} from '../api/equipmentTypeApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

// POST
export const createEquipmentTypeThunk = createAsyncThunk(
  'equipmentType/createEquipmentType',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      console.log('payload', payload);
      const response = await axiosClient.use(createEquipmentTypeApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// GET
export const getEquipmentTypeByIdThunk = createAsyncThunk(
  'equipmentType/getEquipmentTypeById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getEquipmentTypeByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchEquipmentTypesThunk = createAsyncThunk(
  'equipmentType/searchEquipmentTypes',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchEquipmentTypesApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getAllEquipmentTypesThunk = createAsyncThunk(
  'equipmentType/getAllEquipmentTypes',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getAllEquipmentTypesApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const putEquipmentTypeByIdThunk = createAsyncThunk(
  'equipmentType/putEquipmentTypeById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putEquipmentTypeByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// DELETE
export const deleteEquipmentTypeThunk = createAsyncThunk(
  'equipmentType/deleteEquipmentType',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deleteEquipmentTypeApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
