import {createAsyncThunk} from '@reduxjs/toolkit';

import {
  createBrandApi,
  deleteBrandApi,
  getAllBrandsApi,
  getBrandByIdApi,
  putBrandByIdApi,
  searchBrandsApi,
} from '../api/brandApi';
import {axiosClient} from '../axios';
import {UseRequestPayload} from '../axios/axios';

// POST
export const createBrandThunk = createAsyncThunk(
  'brand/createBrand',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      console.log('payload', payload);
      const response = await axiosClient.use(createBrandApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// GET
export const getBrandByIdThunk = createAsyncThunk(
  'brand/getBrandById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getBrandByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const searchBrandsThunk = createAsyncThunk(
  'brand/searchBrands',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(searchBrandsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

export const getAllBrandsThunk = createAsyncThunk(
  'brand/getAllBrands',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(getAllBrandsApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// PUT
export const putBrandByIdThunk = createAsyncThunk(
  'brand/putBrandById',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(putBrandByIdApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);

// DELETE
export const deleteBrandThunk = createAsyncThunk(
  'brand/deleteBrand',
  async (payload: UseRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await axiosClient.use(deleteBrandApi, payload);
      return response?.data?.data;
    } catch (error: any) {
      const errorMsg = error?.data?.message;
      return rejectWithValue(errorMsg);
    }
  },
);
