import {ApiMethod} from '../axios/axios';

// POST METHODS

// GET METHODS
export const searchOrdersApi = {
  url: '/orders/search/admin/all',
  method: ApiMethod.GET,
};

export const getOrderByIdApi = {
  url: 'orders',
  method: ApiMethod.GET,
};

export const getOrderCalendarInfoApi = {
  url: '/orders/calendar',
  method: ApiMethod.GET,
};
