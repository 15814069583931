import {
  HighlightStatus,
  MembershipStatus,
  PaymentMethod,
  PaymentPurpose,
  PaymentStatus,
  ReviewStatus,
  ReviewType,
} from '../enums';
import {WorkerPayStatus} from '../enums/pay';

export const TSL_PAYMENT_METHOD = {
  [PaymentMethod.ALIPAY_HK]: 'Alipay HK',
  [PaymentMethod.CREDIT_CARD]: '信用卡',
  [PaymentMethod.FPS]: 'FPS',
  [PaymentMethod.TRANSFER]: '轉帳',
  [PaymentMethod.APPLE_PAY]: 'Apple Pay',
  [PaymentMethod.PAYPAL_QRCODE]: 'Paypal (QrCode)',
};

export const TSL_PAYMENT_PURPOSE = {
  [PaymentPurpose.DELIVER_FEE]: '出勤費',
  [PaymentPurpose.FINAL_BILL]: '帳單',
};

export const TSL_PAYMENT_STATUS = {
  [PaymentStatus.PAID]: '已付款',
  [PaymentStatus.FAILED]: '付款失敗',
  [PaymentStatus.PENDING]: '等待付款',
  [PaymentStatus.PENDING_APPROVAL]: '已付款，待批核',
  [PaymentStatus.CANCELLED]: '付款取消',
};

export const TSL_HIGHLIGHT_STATUS = {
  [HighlightStatus.ACTIVE]: '顯示',
  [HighlightStatus.INACTIVE]: '隱藏',
};

export const TSL_REVIEW_STATUS = {
  [ReviewStatus.UNREAD]: '未讀',
  [ReviewStatus.READ]: '已讀',
  [ReviewStatus.PROCESSING]: '處理中',
};

export const TSL_REVIEW_TYPE = {
  [ReviewType.ORDER_REPORT]: '售後評價',
  [ReviewType.WORKER_REVIEW]: '師傅評價',
};

export const TSL_CLIENT_STATUS = {
  [MembershipStatus.ACTIVE]: '正常',
  [MembershipStatus.DEACTIVATED]: '停用',
  [MembershipStatus.REVOKED]: '撤銷',
};
export const TSL_WORKER_STATUS = {
  [MembershipStatus.ACTIVE]: '正常',
  [MembershipStatus.DISAPPROVED]: '拒絕審批',
  [MembershipStatus.PENDING_APPROVAL]: '等待審批',
  [MembershipStatus.DEACTIVATED]: '停用',
  [MembershipStatus.REVOKED]: '撤銷',
};

export const TSL_PAY_STATUS = {
  [WorkerPayStatus.PENDING]: '待付款',
  [WorkerPayStatus.PAID]: '已付款',
  [WorkerPayStatus.FAILED]: '異常',
};
